/*eslint-disable*/
import React from "react";
import { graphql } from 'gatsby';

// seo components
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { getSrc } from "gatsby-plugin-image"

import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import SearchComponent from "components/Search/SearchComponent.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import useIsClient from 'hooks/useIsClient';

import styles from "assets/jss/views/contactUsStyleCustom.js";

const useStyles = makeStyles(styles);

export default function SearchPage(props) {

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.searchBannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.searchBannerImage)
    }
  }

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

  const classes = useStyles();

  var appendToH1 = "catalog";

  return (
    <div>

      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />

      <GatsbySeo
        title={'Search our SCENTSY online ' + appendToH1 + props.pageContext.locale.appendToTitle}
        description={'Search our online ' + appendToH1 + ' for wickless warmers + wax, oil diffusers, and everything home fragrance. Customer experience is our priority. Independent Scentsy Consultant since 2011'}
        canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
        language={props.pageContext.locale.isoLanguageCode}
        languageAlternates={[
          {
            hrefLang: props.pageContext.locale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
          {
            hrefLang: props.pageContext.altLocale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
        }
        ]}
        openGraph={{
          type: 'website',
          title: 'Search our SCENTSY online ' + appendToH1 + props.pageContext.locale.appendToTitle,
          description: 'Search our online ' + appendToH1 + ' for wickless warmers + wax, oil diffusers, and everything home fragrance. Customer experience is our priority. Independent Scentsy Consultant since 2011',
          url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          site_name: 'SCENTSY Online Store | Home Fragrance Biz',
          locale: props.pageContext.locale.ogLanguageCode,
          images: [
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Catalog_and_Testers.jpeg',
              width: 3024,
              height: 3024,
              alt: 'Picture of Scentsy catalog and wax testers.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Fragrance_Pods.jpeg',
              width: 2813,
              height: 2813,
              alt: 'Colourful picture of Scentsy fragrance pods surrounding a Wall Fan Diffuser.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Hand_Soap-Hand_Cream.jpeg',
              width: 3022,
              height: 3022,
              alt: 'Picture of Scentsy Hand Soap and Hand Cream next to stack of towels.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Diffuser_Oils.jpeg',
              width: 3024,
              height: 3024,
              alt: 'Picture of Scentsy diffuser oils next to plant on tray.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Wax_Bars_stacked.jpeg',
              width: 2835,
              height: 2835,
              alt: 'Picture of five Scentsy Bars - fragrant wax bars - stacked upside down on countertop.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Wax_Bars_upside_down.jpeg',
              width: 2691,
              height: 1514,
              alt: 'Picture of Scentsy wax bars stacked and showing the back of the bars.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Mini_Warmers_and_Wax_Bars.jpeg',
              width: 3009,
              height: 3009,
              alt: 'Picture of two Scentsy Mini Warmers next to stack of four fragrant Scentsy wax bars.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Himalayan_Salt_Wax_Warmer.jpeg',
              width: 2767,
              height: 2075,
              alt: 'Picture of Scentsy Himalayan Salt wax warmer next to plant on shelf.',
            },
          ],
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
            item: 'https://homefragrancebiz.com',
          },
          {
            position: 2,
            name: 'Search our SCENTSY ' + appendToH1,
            item: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
        ]}
      />

      <Parallax
        filter="dark"
        image={bannerImage}
        small
      >
        <div className={classes.container}>
          <HeaderScentsy />

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.brand}>
                <Typography variant="h5" component="p" className={classes.titleDescription}>
                  What can we help you with?
                </Typography>
                <Typography variant="h3" component="h1" className={classes.title}>
                Search our Scentsy {appendToH1}
                </Typography>
                <Typography variant="h6" component="h2" className={classes.titleDescription}>
                We can help you find the perfect fragrance experience
                for your home, office, home office, car, or vacation.
                </Typography>
              </div>
            </GridItem>
          </GridContainer>

        </div>
      </Parallax>

      <SearchComponent
        locale={props.pageContext.locale}
       />

      <FooterTemplate
        locale={props.pageContext.locale}
      />

    </div>
  );
}

// import banner image
export const query = graphql`
  query {
    searchBannerImage: imageSharp(resize: {originalName: {eq: "HomeFragranceBiz-Scentsy-Catalog_and_Testers.jpeg"}}) {
      gatsbyImageData(width: 1200)
    }
    searchBannerImageMobile: imageSharp(resize: {originalName: {eq: "HomeFragranceBiz-Scentsy-Catalog_and_Testers.jpeg"}}) {
      gatsbyImageData(width: 600)
    }
  }
`